
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from '@/services/i18n';

import { formatCountdownTime, timerAnswerThreshold } from '@/services/util';

export default defineComponent({
  name: 'Timer',

  props: {
    time: { type: Number, required: true },
    threshold: { type: Number, default: timerAnswerThreshold },
    timerRecentlyStarted: { type: Boolean, required: true }
  },

  setup(props) {
    // Presentational hack:
    // Adjust the time so we start with the specified time and finish with zero
    const adjustedTime = computed(() => props.time + 999);

    const thresholdReached = computed(() => adjustedTime.value - 1000 <= props.threshold);

    const formattedTime = computed(() => formatCountdownTime(adjustedTime.value));

    const ariaLive = ref<'off' | 'assertive' | 'polite' | undefined>('off');

    watchEffect(() => {
      if (formattedTime.value.minutes === '01' && formattedTime.value.seconds === '00') {
        ariaLive.value = 'assertive';
      } else if (
        formattedTime.value.minutes === '00' &&
        formattedTime.value.seconds === '10'
      ) {
        ariaLive.value = 'assertive';
      } else {
        ariaLive.value = 'off';
      }
    });

    const t = useI18n().t;

    return {
      t,
      formattedTime,
      thresholdReached,
      minutesLabel: computed(() => t('minutes')),
      secondsLabel: computed(() => t('seconds')),
      ariaLive
    };
  }
});
