
import { defineComponent, onBeforeUnmount, ref, toRef, watch, PropType } from 'vue';
import { useI18n } from '@/services/i18n';
import { timerReflectionThreshold, timerAnswerThreshold } from '@/services/util';
import { injectApiClient } from '@/services/apiClient';
import { CountdownTimer, TimerAbortedError } from '@/services/countdownTimer';
import { PracticeQuestion } from '@/interfaces/interfaces';

import VideoRecorderError from '@/components/common/VideoRecorderError.vue';
import VideoPreview from '@/components/common/VideoPreview.vue';
import videoRecorder from '@/components/mixins/videoRecorder';
import Timer from '@/components/common/Timer.vue';
import RecordingIndicator from '@/components/common/RecordingIndicator.vue';
import VideoUploadStatus from '@/components/common/VideoUploadStatus.vue';
import { asyncTask } from '@/store/lib/asyncTask';

export default defineComponent({
  name: 'PracticeQuestion',
  props: {
    question: { required: true, type: Object as PropType<PracticeQuestion> },
    questionIndex: { required: true, type: Number }
  },
  emits: ['uploaded'],

  components: {
    VideoPreview,
    Timer,
    RecordingIndicator,
    VideoUploadStatus,
    VideoRecorderError
  },

  setup(props, { emit }) {
    const client = injectApiClient();
    const i18n = useI18n();
    const mediaError = ref<Error | null>(null);

    const recorder = videoRecorder();
    const { isCapturing, isRecording, codec } = recorder;

    const isUploading = ref(false);
    const percentageUploaded = ref(0);
    const uploadError = ref(false);
    const showSubmitAlert = ref(false);

    const stream = ref<MediaStream | null>(null);
    const videoSrc = ref<{ src: string; type: string } | null>(null);

    let video: Blob | null = null;

    const timer = new CountdownTimer();

    function reset() {
      isUploading.value = false;
      percentageUploaded.value = 0;
      uploadError.value = false;
      mediaError.value = null;
      showSubmitAlert.value = false;
      stream.value = null;
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      videoSrc.value = null;
      video = null;
      recorder.reset();

      if (timer.isRunning.value) {
        timer.stop();
      }
    }

    async function uploadVideo() {
      isUploading.value = false;
      percentageUploaded.value = 0;
      if (video === null) {
        throw new Error('Video is missing');
      }
      try {
        uploadError.value = false;
        isUploading.value = true;
        const fileName = await client.uploadSystemCheckVideo(
          video,
          percentage => (percentageUploaded.value = Math.round(percentage))
        );
        isUploading.value = false;
        emit('uploaded', fileName);
      } catch (error) {
        isUploading.value = false;
        uploadError.value = true;
        throw error;
      }
    }

    const submitVideo = asyncTask(async () => {
      ({ video } = await recorder.stopRecording());
      recorder.stopCapturing();

      videoSrc.value = {
        src: window.URL.createObjectURL(video),
        type: codec
      };

      if (timer.isRunning.value) {
        timer.stop();
      }
      await uploadVideo();
    });

    async function onSubmitClicked() {
      if (submitVideo.isRunning) {
        return;
      }
      if (showSubmitAlert.value) {
        await submitVideo.run();
        return;
      }
      showSubmitAlert.value = true;
    }

    async function startRecording() {
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      try {
        timer.setup(props.question.answerTime);
        await recorder.startRecording();
        await timer.run();
        if (!submitVideo.isRunning) {
          await submitVideo.run();
        }
      } catch (error) {
        if (!(error instanceof TimerAbortedError)) {
          throw error;
        }
      }
    }

    async function startCapturing() {
      try {
        timer.setup(props.question.reflectionTime);
        stream.value = await recorder.startCapturing();
        mediaError.value = null;
      } catch (error) {
        mediaError.value = error;
        return;
      }
      try {
        await timer.run();
        await startRecording();
      } catch (error) {
        if (!(error instanceof TimerAbortedError)) {
          throw error;
        }
      }
    }

    watch(
      [toRef(props, 'question'), toRef(props, 'questionIndex')],
      async () => {
        reset();
        if (props.questionIndex >= 0) {
          await startCapturing();
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(reset);

    return {
      t: i18n.t,
      i18n,
      mediaError,
      isCapturing,
      isRecording,
      stream,
      startCapturing,
      startRecording,
      onSubmitClicked,
      submitVideo,
      timeLeft: timer.timeLeft,
      isUploading,
      uploadError,
      percentageUploaded,
      timerReflectionThreshold,
      timerAnswerThreshold,
      showSubmitAlert,
      timerRecentlyStarted: timer.timerRecentlyStarted,
      uploadVideo
    };
  }
});
