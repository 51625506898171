import { ref } from 'vue';

export class TimerAbortedError extends Error {}

export class CountdownTimer {
  timeLeft = ref(0);
  isRunning = ref(false);
  timerRecentlyStarted = ref(false);

  protected intervalId: number | null = null;
  protected reject: ((error: TimerAbortedError) => void) | null = null;

  protected clearInterval() {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  setup(time: number) {
    if (this.isRunning.value) {
      throw new Error('Cannot setup timer while it is running');
    }
    this.timeLeft.value = time;
  }

  run(time = this.timeLeft.value) {
    if (this.isRunning.value) {
      throw new Error('Timer already running');
    }

    this.isRunning.value = true;
    this.timerRecentlyStarted.value = true;
    const endTime = Date.now() + time;
    const period = 100;

    // This timeout is necessary to trigger the first announcement of the timer for screen readers
    window.setTimeout(() => {
      this.timerRecentlyStarted.value = false;
    }, 500);

    return new Promise<void>((resolve, reject) => {
      this.reject = reject;
      this.intervalId = window.setInterval(() => {
        this.timeLeft.value = endTime - Date.now();
        if (this.timeLeft.value <= 0) {
          this.clearInterval();
          this.timeLeft.value = 0;
          this.isRunning.value = false;
          resolve();
        }
      }, period);
    });
  }

  stop() {
    if (!this.isRunning.value) {
      throw new Error('Timer is not running');
    }
    this.clearInterval();
    this.isRunning.value = false;
    this.reject?.(new TimerAbortedError('Timer aborted'));
  }
}
