
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';

export default defineComponent({
  name: 'VideoUploadStatus',
  props: {
    isUploading: { type: Boolean },
    uploadError: { type: Boolean }
  },

  setup() {
    return {
      t: useI18n().t
    };
  }
});
