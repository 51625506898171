<template>
  <a-header v-if="!isUploaded" dotted class="py-12">
    <div class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto">
      <h1 class="text-4xl text-white">
        {{ t('practiceTitle') }}
      </h1>
      <p class="mt-3 text-white" role="doc-subtitle">
        {{ t('practiceDescription') }}
      </p>
    </div>
  </a-header>

  <main class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto mt-12">
    <a-alert v-if="!completedSystemCheck" variant="warning" icon class="mb-8">
      <i18n-t keypath="warning" tag="p" class="prose" :i18n="i18n">
        <template #stepTwoLink>
          <router-link :to="{ name: 'applicant.systemCheck' }">
            {{ t('here') }}
          </router-link>
        </template>
      </i18n-t>
    </a-alert>

    <a-collapsible-card
      v-if="!hasPracticeStarted"
      expanded
      fixed
      data-cy="card-practice-instructions"
    >
      <h2 class="text-xl text-blue-darkest">{{ t('practiceInstructionsTitle') }}</h2>
      <template #content>
        <div class="prose list-spread mt-4 pr-4" v-html="t('practiceInstructionsBody')" />
        <a-button
          v-if="!hasPracticeStarted"
          class="mt-10 mb-1 block mx-auto"
          @click="goToNextQuestion()"
          :disabled="!completedSystemCheck"
          data-cy="button-start-practice"
        >
          {{ t('startPractice') }}
        </a-button>
      </template>
    </a-collapsible-card>

    <PracticeQuestion
      v-else-if="hasPracticeStarted && !isUploaded && currentQuestion"
      :question="currentQuestion"
      :questionIndex="currentQuestionIndex"
      @uploaded="markAsUploaded($event)"
    />
    <div v-else class="mt-20 mx-auto">
      <SuccessfullyCompleted>
        <h1 data-cy="txt-upload-successful">
          {{ questionsLeft > 0 ? t('uploadSuccessful') : t('congratulations') }}
        </h1>
        <template #content>
          <div v-if="questionsLeft > 0" class="text-center mt-2">
            <h2>
              {{ t('questionsRemaining', { numRemaining: questionsLeft }) }}
            </h2>
            <a-button
              @click="goToNextQuestion()"
              class="mt-8"
              data-cy="button-next-question"
            >
              {{ t('nextQuestion') }}
            </a-button>
          </div>
          <div v-else>
            <h2 v-html="t('successfullyCompletedStep3')" class="mt-2 text-center"></h2>
            <div class="mt-8 flex justify-center">
              <a-button
                link
                :to="{ name: 'applicant.home' }"
                variant="outline"
                data-cy="button-back-to-home"
              >
                {{ t('backToHome') }}
              </a-button>
              <a-button
                link
                :to="{ name: 'applicant.test' }"
                class="ml-4"
                data-cy="button-proceed-to-interview"
              >
                {{ t('proceedToSnapshotInterview') }}
              </a-button>
            </div>
          </div>
        </template>
      </SuccessfullyCompleted>
      <div v-if="questionsLeft === 0">
        <div class="mt-11 text-center" data-cy="txt-re-watch-practice-responses">
          <h3 class="text-xl text-blue-darkest">
            {{ t('reWatchResponsesTitle') }}
          </h3>
          <p class="mt-4 text-gray-darker" v-html="t('reWatchResponsesDescription')" />
        </div>
        <div class="md:grid md:grid-cols-2 gap-16">
          <div v-for="(question, index) in practiceQuestions" :key="index" class="mt-8">
            <VideoPlayer controls :src="videoUrls[index]" />
            <a-card class="mt-4" data-cy="card-practice-responses-re-watch">
              {{ t('questionTitle') }} {{ index + 1 }}
              <template #content>
                {{ question.text }}
              </template>
            </a-card>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { injectApiClient } from '@/services/apiClient';

import SuccessfullyCompleted from '@/components/common/SuccessfullyCompleted.vue';
import questionsData from '@/assets/data/practiceQuestions';
import PracticeQuestion from '@/components/applicant/PracticeQuestion.vue';
import VideoPlayer from '@/components/common/VideoPlayer.vue';

export default defineComponent({
  name: 'ApplicantPracticeTest',

  components: {
    PracticeQuestion,
    SuccessfullyCompleted,
    VideoPlayer
  },

  setup() {
    const applicant = useStore(applicantStore);
    const client = injectApiClient();
    const i18n = useI18n();

    const completedSystemCheck = computed(() => applicant.isSystemCheckDone);

    const practiceQuestions =
      applicant.currentTest !== null
        ? questionsData[applicant.currentTest.lang]
        : questionsData.en;
    const currentQuestionIndex = ref(-1);
    const currentQuestion = computed(() => practiceQuestions[currentQuestionIndex.value]);
    const questionsLeft = computed(
      () => practiceQuestions.length - (currentQuestionIndex.value + 1)
    );

    const hasPracticeStarted = computed(() => currentQuestionIndex.value > -1);

    const isUploaded = ref(false);
    const videoFileNames: string[] = [];
    const videoUrls = ref<string[]>([]);

    async function markAsUploaded(fileName: string) {
      isUploaded.value = true;
      videoFileNames.push(fileName);
      if (questionsLeft.value === 0) {
        applicant.markPracticeDone();
        videoUrls.value = await Promise.all(
          videoFileNames.map(fileName => client.getPracticeVideoUrl(fileName))
        );
      }
    }

    function goToNextQuestion() {
      currentQuestionIndex.value++;
      isUploaded.value = false;
    }

    return {
      t: i18n.t,
      i18n,
      completedSystemCheck,
      hasPracticeStarted,
      isUploaded,
      practiceQuestions,
      currentQuestion,
      currentQuestionIndex,
      questionsLeft,
      markAsUploaded,
      goToNextQuestion,
      videoUrls
    };
  }
});
</script>

<i18n>
{
  "en": {
    "backToHome": "Back to home",
    "congratulations": "Congratulations!",
    "here": "here",
    "nextQuestion": "Next question",
    "practiceDescription": "These questions are for practice only. Your responses to these practice questions will not be sent to your chosen programs and are intended for you to get comfortable with the Snapshot experience only.",
    "practiceInstructionsBody": "<p>Welcome to the practice questions for Snapshot. Here you will be able to practice recording a Snapshot video response. Use the opportunity to complete these practice questions as many times as you like until you feel comfortable with the format, as you will not be able to re-record yourself once you actually start Snapshot.</p><ul><li>You have <b>2 practice interview-style questions</b> to practice answering. Your recorded responses will be limited to 02:00 minutes each in length.</li><li>You will only have a 30 second opportunity to read and reflect on each question. Once the 30 seconds is over you will be redirected to the interview, where the 02:00 minute timer will start automatically.</li><li>When you are ready to begin click “Start Practice”. You will be prompted to <b>give permission</b> to your web camera and microphone in order to practice recording your responses. You must complete Step 2: System requirements check before proceeding to Step 3: Practice Snapshot.</li><li><b>Watch your practice responses after you’ve finished both questions</b> to ensure your video and sound recorded successfully. <strong>Note: you will not be able to watch your real Snapshot response after you have recorded it. Only the practice ones here.</strong></li></ul>",
    "practiceInstructionsTitle": "Instructions",
    "practiceTitle": "Practice Snapshot",
    "proceedToSnapshotInterview": "Proceed to Snapshot Interview",
    "questionTitle": "Practice Question",
    "questionsRemaining": "You have {numRemaining} more practice question(s) remaining.",
    "reWatchResponsesDescription": "Watch your recorded response to check your audio and video uploaded successfully.<br/><strong>You will not be able to watch your real Snapshot Interview responses after you have recorded it. Only the practice ones here.</strong>",
    "reWatchResponsesTitle": "Re-watch your responses",
    "startPractice": "Start practice",
    "successfullyCompletedStep3": "You've successfully completed<br/>Step 3: Practice Snapshot!",
    "uploadSuccessful": "Upload successful",
    "warning": "You must complete Step 2: System requirements check before proceeding to Step 3: Practice Snapshot. Click {stepTwoLink} to go to Step 2."
  },
  "fr": {
    "backToHome": "Retourner à l'accueil",
    "congratulations": "Félicitations !",
    "here": "ici",
    "nextQuestion": "Question suivante",
    "practiceDescription": "Ces questions sont destinées seulement à l'entraînement. Vos réponses à ces questions d'entraînement ne seront pas envoyées aux programmes de votre liste de distribution, et sont destinées seulement à vous mettre à l'aise avec le format de Snapshot.",
    "practiceInstructionsBody": "<p>Bienvenue dans le module d'entraînement de Snapshot. Ici, vous pouvez vous entraîner à enregistrer une réponse vidéo Snapshot. Utilisez cette opportunité pour répondre à ces questions d'entraînement autant de fois que vous le souhaitez jusqu'à ce que vous soyez à l'aise avec le format, car vous ne pourrez pas réenregistrer vos réponses lorsque vous compléterez la réelle entrevue Snapshot.</p><ul><li>Vous avez accès à <b>2 questions de type entrevue</b> pour vous entraîner à répondre. Vos réponses enregistrées sont limitées à 02:00 minutes chacune.</li><li>Vous aurez seulement 30 secondes pour lire et réfléchir à chaque question. Une fois les 30 secondes écoulées, vous serez dirigé(e) vers l'entrevue, où l'enregistrement de 02:00 minutes commencera automatiquement.</li>\n<li>Quand vous êtes prêt(e), cliquez sur « Commencer l'entraînement ». Il vous sera demandé <b>d'autoriser l'accès</b> à votre webcam et à votre microphone afin d'enregistrer vos réponses d'entraînement. Vous devez compléter l'étape 2 : Vérification du système avant de pouvoir passer à l'étape 3 : Entraînement Snapshot.</li><li><b>Visionnez vos réponses d'entraînement après les avoir enregistrées pour vous assurer que votre vidéo et audio aient correctement fonctionné.</b><strong>Note : vous ne pourrez pas visionner vos réponses à la réelle entrevue Snapshot après les avoir enregistrées. Seulement celles de l'entraînement ici.</strong></li></ul>",
    "practiceInstructionsTitle": "Instructions",
    "practiceTitle": "Entraînement Snapshot",
    "proceedToSnapshotInterview": "Accéder à l'entrevue Snapshot",
    "questionTitle": "Question d'entraînement",
    "questionsRemaining": "Il vous reste {numRemaining} question(s) d'entraînement.",
    "reWatchResponsesDescription": "Visionnez vos réponses enregistrées pour vérifier que votre audio et vidéo aient fonctionné correctement.<br/><strong>Vous ne pourrez pas visionner vos réponses à la réelle entrevue Snapshot après les avoir enregistrées. Seulement celles de l'entraînement ici.</strong>",
    "reWatchResponsesTitle": "Visionnez vos réponses",
    "startPractice": "Commencer l'entraînement",
    "successfullyCompletedStep3": "Vous avez complété<br/> l'étape 3 : Entraînement Snapshot !",
    "uploadSuccessful": "Téléversement réussi",
    "warning": "Vous devez compléter l'étape 2 : Vérification du système avant de pouvoir passer à l'étape 3 : Entraînement Snapshot. Cliquez {stepTwoLink} pour passer à l'étape 2."
  }
}
</i18n>
