<template>
  <div
    class="h-80 text-center text-blue-darkest flex flex-column items-center justify-center"
  >
    <div v-if="isUploading">
      <img src="@/assets/icons/uploading.svg" class="w-9 mx-auto mb-4" alt="" />
      <span v-html="t('uploading')" data-cy="txt-video-uploading"></span>
    </div>

    <div v-if="uploadError">
      <img src="@/assets/icons/upload-failed.svg" class="w-14 mx-auto mb-2" alt="" />
      <span v-html="t('uploadError')"></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';

export default defineComponent({
  name: 'VideoUploadStatus',
  props: {
    isUploading: { type: Boolean },
    uploadError: { type: Boolean }
  },

  setup() {
    return {
      t: useI18n().t
    };
  }
});
</script>

<i18n>
{
  "en": {
    "uploadError": "Video upload fail<br />Please try again.",
    "uploading": "Video Uploading.<br />This may take up to a few minutes.<br />Please do not close this screen."
  },
  "fr": {
    "uploadError": "Le téléversement a échoué. <br />Veuillez réessayer.",
    "uploading": "Téléversement de la vidéo en cours. <br />Cela peut prendre quelques minutes.<br />Veuillez ne pas fermer cette fenêtre."
  }
}
</i18n>
