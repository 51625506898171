export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video upload fail<br />Please try again."])},
        "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Uploading.<br />This may take up to a few minutes.<br />Please do not close this screen."])}
      },
      "fr": {
        "uploadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléversement a échoué. <br />Veuillez réessayer."])},
        "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement de la vidéo en cours. <br />Cela peut prendre quelques minutes.<br />Veuillez ne pas fermer cette fenêtre."])}
      }
    }
  })
}
