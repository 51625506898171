export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
        "congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
        "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
        "nextQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next question"])},
        "practiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These questions are for practice only. Your responses to these practice questions will not be sent to your chosen programs and are intended for you to get comfortable with the Snapshot experience only."])},
        "practiceInstructionsBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Welcome to the practice questions for Snapshot. Here you will be able to practice recording a Snapshot video response. Use the opportunity to complete these practice questions as many times as you like until you feel comfortable with the format, as you will not be able to re-record yourself once you actually start Snapshot.</p><ul><li>You have <b>2 practice interview-style questions</b> to practice answering. Your recorded responses will be limited to 02:00 minutes each in length.</li><li>You will only have a 30 second opportunity to read and reflect on each question. Once the 30 seconds is over you will be redirected to the interview, where the 02:00 minute timer will start automatically.</li><li>When you are ready to begin click “Start Practice”. You will be prompted to <b>give permission</b> to your web camera and microphone in order to practice recording your responses. You must complete Step 2: System requirements check before proceeding to Step 3: Practice Snapshot.</li><li><b>Watch your practice responses after you’ve finished both questions</b> to ensure your video and sound recorded successfully. <strong>Note: you will not be able to watch your real Snapshot response after you have recorded it. Only the practice ones here.</strong></li></ul>"])},
        "practiceInstructionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
        "practiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Snapshot"])},
        "proceedToSnapshotInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to Snapshot Interview"])},
        "questionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Question"])},
        "questionsRemaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("numRemaining")), " more practice question(s) remaining."])},
        "reWatchResponsesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch your recorded response to check your audio and video uploaded successfully.<br/><strong>You will not be able to watch your real Snapshot Interview responses after you have recorded it. Only the practice ones here.</strong>"])},
        "reWatchResponsesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-watch your responses"])},
        "startPractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start practice"])},
        "successfullyCompletedStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You've successfully completed<br/>Step 3: Practice Snapshot!"])},
        "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload successful"])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You must complete Step 2: System requirements check before proceeding to Step 3: Practice Snapshot. Click ", _interpolate(_named("stepTwoLink")), " to go to Step 2."])}
      },
      "fr": {
        "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])},
        "congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations !"])},
        "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
        "nextQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question suivante"])},
        "practiceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ces questions sont destinées seulement à l'entraînement. Vos réponses à ces questions d'entraînement ne seront pas envoyées aux programmes de votre liste de distribution, et sont destinées seulement à vous mettre à l'aise avec le format de Snapshot."])},
        "practiceInstructionsBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Bienvenue dans le module d'entraînement de Snapshot. Ici, vous pouvez vous entraîner à enregistrer une réponse vidéo Snapshot. Utilisez cette opportunité pour répondre à ces questions d'entraînement autant de fois que vous le souhaitez jusqu'à ce que vous soyez à l'aise avec le format, car vous ne pourrez pas réenregistrer vos réponses lorsque vous compléterez la réelle entrevue Snapshot.</p><ul><li>Vous avez accès à <b>2 questions de type entrevue</b> pour vous entraîner à répondre. Vos réponses enregistrées sont limitées à 02:00 minutes chacune.</li><li>Vous aurez seulement 30 secondes pour lire et réfléchir à chaque question. Une fois les 30 secondes écoulées, vous serez dirigé(e) vers l'entrevue, où l'enregistrement de 02:00 minutes commencera automatiquement.</li>\n<li>Quand vous êtes prêt(e), cliquez sur « Commencer l'entraînement ». Il vous sera demandé <b>d'autoriser l'accès</b> à votre webcam et à votre microphone afin d'enregistrer vos réponses d'entraînement. Vous devez compléter l'étape 2 : Vérification du système avant de pouvoir passer à l'étape 3 : Entraînement Snapshot.</li><li><b>Visionnez vos réponses d'entraînement après les avoir enregistrées pour vous assurer que votre vidéo et audio aient correctement fonctionné.</b><strong>Note : vous ne pourrez pas visionner vos réponses à la réelle entrevue Snapshot après les avoir enregistrées. Seulement celles de l'entraînement ici.</strong></li></ul>"])},
        "practiceInstructionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructions"])},
        "practiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraînement Snapshot"])},
        "proceedToSnapshotInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à l'entrevue Snapshot"])},
        "questionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question d'entraînement"])},
        "questionsRemaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il vous reste ", _interpolate(_named("numRemaining")), " question(s) d'entraînement."])},
        "reWatchResponsesDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visionnez vos réponses enregistrées pour vérifier que votre audio et vidéo aient fonctionné correctement.<br/><strong>Vous ne pourrez pas visionner vos réponses à la réelle entrevue Snapshot après les avoir enregistrées. Seulement celles de l'entraînement ici.</strong>"])},
        "reWatchResponsesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visionnez vos réponses"])},
        "startPractice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l'entraînement"])},
        "successfullyCompletedStep3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez complété<br/> l'étape 3 : Entraînement Snapshot !"])},
        "uploadSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement réussi"])},
        "warning": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous devez compléter l'étape 2 : Vérification du système avant de pouvoir passer à l'étape 3 : Entraînement Snapshot. Cliquez ", _interpolate(_named("stepTwoLink")), " pour passer à l'étape 2."])}
      }
    }
  })
}
