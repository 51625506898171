
import { defineComponent, ref, computed } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { injectApiClient } from '@/services/apiClient';

import SuccessfullyCompleted from '@/components/common/SuccessfullyCompleted.vue';
import questionsData from '@/assets/data/practiceQuestions';
import PracticeQuestion from '@/components/applicant/PracticeQuestion.vue';
import VideoPlayer from '@/components/common/VideoPlayer.vue';

export default defineComponent({
  name: 'ApplicantPracticeTest',

  components: {
    PracticeQuestion,
    SuccessfullyCompleted,
    VideoPlayer
  },

  setup() {
    const applicant = useStore(applicantStore);
    const client = injectApiClient();
    const i18n = useI18n();

    const completedSystemCheck = computed(() => applicant.isSystemCheckDone);

    const practiceQuestions =
      applicant.currentTest !== null
        ? questionsData[applicant.currentTest.lang]
        : questionsData.en;
    const currentQuestionIndex = ref(-1);
    const currentQuestion = computed(() => practiceQuestions[currentQuestionIndex.value]);
    const questionsLeft = computed(
      () => practiceQuestions.length - (currentQuestionIndex.value + 1)
    );

    const hasPracticeStarted = computed(() => currentQuestionIndex.value > -1);

    const isUploaded = ref(false);
    const videoFileNames: string[] = [];
    const videoUrls = ref<string[]>([]);

    async function markAsUploaded(fileName: string) {
      isUploaded.value = true;
      videoFileNames.push(fileName);
      if (questionsLeft.value === 0) {
        applicant.markPracticeDone();
        videoUrls.value = await Promise.all(
          videoFileNames.map(fileName => client.getPracticeVideoUrl(fileName))
        );
      }
    }

    function goToNextQuestion() {
      currentQuestionIndex.value++;
      isUploaded.value = false;
    }

    return {
      t: i18n.t,
      i18n,
      completedSystemCheck,
      hasPracticeStarted,
      isUploaded,
      practiceQuestions,
      currentQuestion,
      currentQuestionIndex,
      questionsLeft,
      markAsUploaded,
      goToNextQuestion,
      videoUrls
    };
  }
});
