<template>
  <div class="flex text-4xl" :class="thresholdReached ? 'text-red' : 'text-blue-darkest'">
    <div aria-hidden="true" class="flex items-center flex-col">
      <div>
        {{ formattedTime.minutes }}
      </div>
      <span class="text-base font-normal -mt-2 text-gray-darker">
        {{ minutesLabel }}
      </span>
    </div>
    <span aria-hidden="true">:</span>
    <div aria-hidden="true" class="flex items-center flex-col">
      <div>
        {{ formattedTime.seconds }}
      </div>
      <span class="text-base font-normal -mt-2 text-gray-darker">
        {{ secondsLabel }}
      </span>
    </div>
    <span
      v-if="timerRecentlyStarted"
      class="sr-only"
      role="timer"
      aria-atomic="true"
      aria-live="assertive"
    >
      {{
        t('srTimerStarted', { mins: formattedTime.minutes, secs: formattedTime.seconds })
      }}
    </span>
    <span v-else class="sr-only" role="timer" aria-atomic="true" :aria-live="ariaLive">
      {{ t('srTimeLeft', { mins: formattedTime.minutes, secs: formattedTime.seconds }) }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { useI18n } from '@/services/i18n';

import { formatCountdownTime, timerAnswerThreshold } from '@/services/util';

export default defineComponent({
  name: 'Timer',

  props: {
    time: { type: Number, required: true },
    threshold: { type: Number, default: timerAnswerThreshold },
    timerRecentlyStarted: { type: Boolean, required: true }
  },

  setup(props) {
    // Presentational hack:
    // Adjust the time so we start with the specified time and finish with zero
    const adjustedTime = computed(() => props.time + 999);

    const thresholdReached = computed(() => adjustedTime.value - 1000 <= props.threshold);

    const formattedTime = computed(() => formatCountdownTime(adjustedTime.value));

    const ariaLive = ref<'off' | 'assertive' | 'polite' | undefined>('off');

    watchEffect(() => {
      if (formattedTime.value.minutes === '01' && formattedTime.value.seconds === '00') {
        ariaLive.value = 'assertive';
      } else if (
        formattedTime.value.minutes === '00' &&
        formattedTime.value.seconds === '10'
      ) {
        ariaLive.value = 'assertive';
      } else {
        ariaLive.value = 'off';
      }
    });

    const t = useI18n().t;

    return {
      t,
      formattedTime,
      thresholdReached,
      minutesLabel: computed(() => t('minutes')),
      secondsLabel: computed(() => t('seconds')),
      ariaLive
    };
  }
});
</script>

<i18n>
{
  "en": {
    "minutes": "mins",
    "seconds": "secs",
    "srTimeLeft": "Time left {mins} minutes and {secs} seconds.",
    "srTimerStarted": "Timer started. Time left {mins} minutes and {secs} seconds."
  },
  "fr": {
    "minutes": "minutes",
    "seconds": "secondes",
    "srTimeLeft": "Temps restant : {mins} minutes et {secs} secondes.",
    "srTimerStarted": "Chronomètre commencé. Temps restant : {mins} minutes et {secs} secondes."
  }
}
</i18n>
