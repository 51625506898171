export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "questionTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Practice Question ", _interpolate(_named("questionNumber"))])},
        "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording"])},
        "recordingStartsIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording starts in:"])},
        "reflectionPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read and Reflect"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
        "submitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to submit your response, select submit again to confirm."])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troubleshooting Tips"])},
        "uploadErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unable to upload. Please click “Retry” to try again or visit our ", _interpolate(_named("troubleshootingLink")), "."])},
        "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading..."])}
      },
      "fr": {
        "questionTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question d'entraînement ", _interpolate(_named("questionNumber"))])},
        "recording": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement"])},
        "recordingStartsIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'enregistrement commence dans :"])},
        "reflectionPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture et réflexion"])},
        "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
        "submitAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de soumettre votre réponse, veuillez cliquer de nouveau sur Soumettre pour confirmer l'envoi."])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils de dépannage"])},
        "uploadErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le téléversement a échoué. Veuillez cliquer sur « Réessayer » pour essayer de nouveau ou lire nos ", _interpolate(_named("troubleshootingLink"))])},
        "uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement..."])}
      }
    }
  })
}
