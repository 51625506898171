import { PracticeQuestion } from '@/interfaces/interfaces';

const practiceQuestions: { en: PracticeQuestion[]; fr: PracticeQuestion[] } = {
  en: [
    {
      lang: 'en',
      text:
        'What do you think will be the most challenging aspect about your future profession?',
      reflectionTime: 30000,
      answerTime: 120000
    },
    {
      lang: 'en',
      text:
        'During the admissions process, what is one factor that you think admissions committees should put more weight on and one they should put less weight on?',
      reflectionTime: 30000,
      answerTime: 120000
    }
  ],
  fr: [
    {
      lang: 'fr',
      text: "Selon vous, quel sera l'aspect le plus exigeant de votre future profession?",
      reflectionTime: 30000,
      answerTime: 120000
    },
    {
      lang: 'fr',
      text:
        'Dans le processus d’admission, selon vous quel est le facteur auquel les comités d’admission devraient accorder plus de valeur, et celui auquel ils devraient en accorder moins?',
      reflectionTime: 30000,
      answerTime: 120000
    }
  ]
};

export default practiceQuestions;
