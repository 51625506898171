export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mins"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secs"])},
        "srTimeLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Time left ", _interpolate(_named("mins")), " minutes and ", _interpolate(_named("secs")), " seconds."])},
        "srTimerStarted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Timer started. Time left ", _interpolate(_named("mins")), " minutes and ", _interpolate(_named("secs")), " seconds."])}
      },
      "fr": {
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["secondes"])},
        "srTimeLeft": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Temps restant : ", _interpolate(_named("mins")), " minutes et ", _interpolate(_named("secs")), " secondes."])},
        "srTimerStarted": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Chronomètre commencé. Temps restant : ", _interpolate(_named("mins")), " minutes et ", _interpolate(_named("secs")), " secondes."])}
      }
    }
  })
}
