<template>
  <VideoRecorderError
    v-if="mediaError !== null"
    :error="mediaError"
    class="w-1/2 mx-auto mt-10"
    @retry="startCapturing()"
  />
  <div v-else-if="isCapturing" class="mt-10 sm:w-3/4 lg:w-1/2 mx-auto text-blue-darkest">
    <div class="flex justify-between items-end text-xl">
      <h2 v-if="!isRecording" class="mb-4">{{ t('reflectionPeriod') }}</h2>
      <div v-else class="flex justify-center items-center mb-4">
        <RecordingIndicator class="mr-2" />
        <h2>{{ t('recording') }}</h2>
      </div>
      <div>
        <p :class="{ invisible: isRecording }" class="text-gray-darker text-base">
          {{ t('recordingStartsIn') }}
        </p>
        <Timer
          :time="timeLeft"
          :threshold="!isRecording ? timerReflectionThreshold : timerAnswerThreshold"
          :timerRecentlyStarted="timerRecentlyStarted"
          class="justify-end"
        />
      </div>
    </div>
    <div class="mt-1">
      <VideoPreview :stream="stream ?? undefined" />
      <a-card class="mt-4">
        <span data-cy="txt-title-question-number">
          {{ t('questionTitle', { questionNumber: questionIndex + 1 }) }}
        </span>
        <template #content>
          {{ question.text }}
        </template>
      </a-card>
      <div class="mt-4 text-center">
        <a-alert
          v-if="showSubmitAlert"
          variant="warning"
          class="mb-2"
          data-cy="alert-submit-confirmation"
        >
          {{ t('submitAlert') }}
        </a-alert>
        <a-button
          @click="onSubmitClicked()"
          variant="danger"
          :disabled="!isRecording || submitVideo.isRunning"
          data-cy="button-submit"
        >
          {{ t('submit') }}
        </a-button>
      </div>
    </div>
  </div>
  <div v-else-if="isUploading || uploadError" class="mt-10 sm:w-3/4 lg:w-1/2 mx-auto">
    <VideoUploadStatus :isUploading="isUploading" :uploadError="uploadError" />
    <a-card class="my-4">
      <span>{{ t('questionTitle', { questionNumber: questionIndex + 1 }) }}</span>
      <template #content>
        {{ question.text }}
      </template>
    </a-card>
    <div v-if="isUploading" class="text-center" data-cy="progress-bar-uploading">
      <span class="text-sm text-blue-darkest">{{ t('uploading') }}</span>
      <a-progress-bar variant="secondary" show-number :percentage="percentageUploaded" />
    </div>
    <div v-if="uploadError" class="text-center">
      <a-alert class="mt-4" variant="danger" icon dismissible>
        <i18n-t keypath="uploadErrorMessage" tag="p" class="prose" :i18n="i18n">
          <template #troubleshootingLink>
            <router-link :to="{ name: 'applicant.troubleshooting' }" target="_blank">
              {{ t('troubleshootingTips') }}
            </router-link>
          </template>
        </i18n-t>
      </a-alert>
      <a-button class="mt-4" @click="uploadVideo()">{{ t('retry') }}</a-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, ref, toRef, watch, PropType } from 'vue';
import { useI18n } from '@/services/i18n';
import { timerReflectionThreshold, timerAnswerThreshold } from '@/services/util';
import { injectApiClient } from '@/services/apiClient';
import { CountdownTimer, TimerAbortedError } from '@/services/countdownTimer';
import { PracticeQuestion } from '@/interfaces/interfaces';

import VideoRecorderError from '@/components/common/VideoRecorderError.vue';
import VideoPreview from '@/components/common/VideoPreview.vue';
import videoRecorder from '@/components/mixins/videoRecorder';
import Timer from '@/components/common/Timer.vue';
import RecordingIndicator from '@/components/common/RecordingIndicator.vue';
import VideoUploadStatus from '@/components/common/VideoUploadStatus.vue';
import { asyncTask } from '@/store/lib/asyncTask';

export default defineComponent({
  name: 'PracticeQuestion',
  props: {
    question: { required: true, type: Object as PropType<PracticeQuestion> },
    questionIndex: { required: true, type: Number }
  },
  emits: ['uploaded'],

  components: {
    VideoPreview,
    Timer,
    RecordingIndicator,
    VideoUploadStatus,
    VideoRecorderError
  },

  setup(props, { emit }) {
    const client = injectApiClient();
    const i18n = useI18n();
    const mediaError = ref<Error | null>(null);

    const recorder = videoRecorder();
    const { isCapturing, isRecording, codec } = recorder;

    const isUploading = ref(false);
    const percentageUploaded = ref(0);
    const uploadError = ref(false);
    const showSubmitAlert = ref(false);

    const stream = ref<MediaStream | null>(null);
    const videoSrc = ref<{ src: string; type: string } | null>(null);

    let video: Blob | null = null;

    const timer = new CountdownTimer();

    function reset() {
      isUploading.value = false;
      percentageUploaded.value = 0;
      uploadError.value = false;
      mediaError.value = null;
      showSubmitAlert.value = false;
      stream.value = null;
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      videoSrc.value = null;
      video = null;
      recorder.reset();

      if (timer.isRunning.value) {
        timer.stop();
      }
    }

    async function uploadVideo() {
      isUploading.value = false;
      percentageUploaded.value = 0;
      if (video === null) {
        throw new Error('Video is missing');
      }
      try {
        uploadError.value = false;
        isUploading.value = true;
        const fileName = await client.uploadSystemCheckVideo(
          video,
          percentage => (percentageUploaded.value = Math.round(percentage))
        );
        isUploading.value = false;
        emit('uploaded', fileName);
      } catch (error) {
        isUploading.value = false;
        uploadError.value = true;
        throw error;
      }
    }

    const submitVideo = asyncTask(async () => {
      ({ video } = await recorder.stopRecording());
      recorder.stopCapturing();

      videoSrc.value = {
        src: window.URL.createObjectURL(video),
        type: codec
      };

      if (timer.isRunning.value) {
        timer.stop();
      }
      await uploadVideo();
    });

    async function onSubmitClicked() {
      if (submitVideo.isRunning) {
        return;
      }
      if (showSubmitAlert.value) {
        await submitVideo.run();
        return;
      }
      showSubmitAlert.value = true;
    }

    async function startRecording() {
      if (videoSrc.value !== null) {
        window.URL.revokeObjectURL(videoSrc.value.src);
      }
      try {
        timer.setup(props.question.answerTime);
        await recorder.startRecording();
        await timer.run();
        if (!submitVideo.isRunning) {
          await submitVideo.run();
        }
      } catch (error) {
        if (!(error instanceof TimerAbortedError)) {
          throw error;
        }
      }
    }

    async function startCapturing() {
      try {
        timer.setup(props.question.reflectionTime);
        stream.value = await recorder.startCapturing();
        mediaError.value = null;
      } catch (error) {
        mediaError.value = error;
        return;
      }
      try {
        await timer.run();
        await startRecording();
      } catch (error) {
        if (!(error instanceof TimerAbortedError)) {
          throw error;
        }
      }
    }

    watch(
      [toRef(props, 'question'), toRef(props, 'questionIndex')],
      async () => {
        reset();
        if (props.questionIndex >= 0) {
          await startCapturing();
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(reset);

    return {
      t: i18n.t,
      i18n,
      mediaError,
      isCapturing,
      isRecording,
      stream,
      startCapturing,
      startRecording,
      onSubmitClicked,
      submitVideo,
      timeLeft: timer.timeLeft,
      isUploading,
      uploadError,
      percentageUploaded,
      timerReflectionThreshold,
      timerAnswerThreshold,
      showSubmitAlert,
      timerRecentlyStarted: timer.timerRecentlyStarted,
      uploadVideo
    };
  }
});
</script>

<i18n>
{
  "en": {
    "questionTitle": "Practice Question {questionNumber}",
    "recording": "Recording",
    "recordingStartsIn": "Recording starts in:",
    "reflectionPeriod": "Read and Reflect",
    "retry": "Retry",
    "submit": "Submit",
    "submitAlert": "You are about to submit your response, select submit again to confirm.",
    "troubleshootingTips": "Troubleshooting Tips",
    "uploadErrorMessage": "Unable to upload. Please click “Retry” to try again or visit our {troubleshootingLink}.",
    "uploading": "Uploading..."
  },
  "fr": {
    "questionTitle": "Question d'entraînement {questionNumber}",
    "recording": "Enregistrement",
    "recordingStartsIn": "L'enregistrement commence dans :",
    "reflectionPeriod": "Lecture et réflexion",
    "retry": "Réessayer",
    "submit": "Soumettre",
    "submitAlert": "Vous êtes sur le point de soumettre votre réponse, veuillez cliquer de nouveau sur Soumettre pour confirmer l'envoi.",
    "troubleshootingTips": "Conseils de dépannage",
    "uploadErrorMessage": "Le téléversement a échoué. Veuillez cliquer sur « Réessayer » pour essayer de nouveau ou lire nos {troubleshootingLink}",
    "uploading": "Téléversement..."
  }
}
</i18n>
